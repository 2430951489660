import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (menuItem) => {
    return (_openBlock(), _createElementBlock("div", {
      key: menuItem.id,
      class: "menu-item px-5"
    }, [
      _createVNode(_component_router_link, {
        to: '/' + menuItem.route,
        class: "menu-link px-5",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modal()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(menuItem.label), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]))
  }), 128))
}