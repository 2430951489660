
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import Usermenu from "@/layout/aside/Usermenu.vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";

export default defineComponent({
  name: "kt-activity-drawer",
  components: {
    Usermenu
  },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const { t } = useI18n();
    const authStore = useAuthStore();
    
    const signOut = () => {
      authStore.logout();
      // store.dispatch(Actions.LOGOUT).then(() => {
        const drawer = DrawerComponent.getInstance("es_user_menu");

        if (!drawer) {
          return;
        }

        drawer.hide();
        
        router.push({ name: "sign-in" })
      // });
    };

    onMounted(() => {
      DrawerComponent.reinitialization();
    });

    return {
      // user,
      authStore,
      signOut,
      i18n,
      t,
    }
  }
});
