
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

import { useStore } from "vuex";
import { useAppStore } from "@/stores/app";

export default defineComponent({
  name: "",
  components: {},
  setup() {
    //ES
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const appStore = useAppStore();

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    const menuList = computed(() => {
      return appStore.getConfig('menus.usermenu');
    });

    const modal = () => {
      const drawer = DrawerComponent.getInstance("es_usermenu");

      if (!drawer){
        return;
      }

      drawer.hide();
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      menuList, 
      modal
    };
  }
});
